import React from "react";
import "./Home.css";
import Contacts from "./Contacts";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <div className="contents container-fluid mt-5 ">
        <div className="row">
          <div className="col">
            <img
              src="logo1x.png"
              srcSet="logo1x.png 1x,logo2x.png"
              alt="ETeq"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3 className="mt-5 mb-3">会社概要</h3>
            <div className="row pl-sm-5 pl-1">
              <div className="col">
                株式会社ETeqは、テクノロジーによって教育現場をより良くし、好奇心を育てる教育環境を提供することを目指しています。
                教育現場へのデジタル技術の導入、エンジニアリング教育、教育現場の効率化と活性化のための事業を行っています。
              </div>
            </div>
            <h3 className="mt-5 mb-3">ニュース</h3>
            <div className="row pl-sm-5 pl-1">
              {/* ニュース7 */}
              <div className="col-sm-2 col-12">2024.07.23 </div>
              <div className="col-sm-10 col-12">
                <a href="https://www.code4lib.jp/">
                  Code4Lib JAPAN カンファレンス
                </a>
                での登壇が決定しました。Yomumoについての発表をします。
              </div>{" "}
              {/* ニュース6 */}
              <div className="col-sm-2 col-12">2023.03.02 </div>
              <div className="col-sm-10 col-12">
                <a href="https://prtimes.jp/main/html/rd/p/000000023.000049026.html">
                  PR TIMES
                </a>
                に「Yomumo」にの紹介記事が掲載されました。
              </div>{" "}
              {/* ニュース5 */}
              <div className="col-sm-2 col-12">2022.09.17 </div>
              <div className="col-sm-10 col-12">
                <a href="https://peatix.com/event/3278218/view">
                  令和4年度日本学校図書館学会研究発表大会
                </a>
                で「Yomumo」を使った教育研究成果を発表します。
              </div>
              {/* ニュース4 */}
              <div className="col-sm-2 col-12">2022.03.17 </div>
              <div className="col-sm-10 col-12">
                <a href="http://toin.ac.jp/ek-vs/?p=1760">
                  桐蔭学園小学校の教育実践のページ
                </a>
                に読書感想カード共有アプリ「Yomumo」に関する記事が掲載されました。
              </div>
              {/* ニュース3 */}
              <div className="col-sm-2 col-12">2021.09.30 </div>
              <div className="col-sm-10 col-12">
                <a href="https://edtechpitch-210930.peatix.com/">
                  Startup Pitch@CIC EdTech 〜教育 x Technology〜
                </a>
                で弊社の取り組みを発表します。
              </div>
              {/* ニュース2 */}
              <div className="col-sm-2 col-12">2020.02.19 </div>
              <div className="col-sm-10 col-12">
                <a href="https://scheemd.mext.go.jp/archives/2020/idealist.html">
                  文部科学省「大学教育のデジタライゼーション・
                  イニシアティブ」のピッチイベントで登壇しました。
                </a>
              </div>
              {/* ニュース1 */}
              <div className="col-sm-2 col-12">2020.01.29 </div>
              <div className="col-sm-10 col-12">
                <a href="https://scheemd.mext.go.jp/">
                  文部科学省「大学教育のデジタライゼーション・
                  イニシアティブ(Scheme-D)」
                </a>
                に採択されました。
              </div>
            </div>
            <h3 className="mt-5 mb-3">製品情報</h3>
            <div className="row pl-sm-5 pl-1">
              <div className="col-sm-7 col-12">
                <h5>Yomumo (よむも)</h5>
                学校の図書室で読んだ図書の感想を記録し共有するアプリです。
                タブレットのカメラで図書の裏表紙のバーコードをスキャンするだけで、
                本のタイトル・著者名・表紙などの情報を簡単に取り込むことができます。
                感想はキーボード入力・手書き入力両方に対応しています。
                タブレット(Android/iOS)・ノートパソコンなどに対応しています。
                <a href="/yomumo/">詳しくはこちら</a>。
              </div>
              <div className="col-sm-5 col-12">
                <img
                  className="img-fluid"
                  src="yomumo/yomumo-screenshot.png"
                  alt="yomumo"
                />
              </div>
            </div>

            <h3 className="mt-5 mb-3">企業情報</h3>
            <div className="row pl-sm-5 pl-1">
              <div className="col">
                <table className="table table-borderless text-black-70 pl-5">
                  <tr>
                    <th className="text-right">商号</th>
                    <td>株式会社ETeq</td>
                  </tr>
                  <tr>
                    <th className="text-right">設立</th>
                    <td>2020年5月7日</td>
                  </tr>
                  <tr>
                    <th className="text-right">本社所在地</th>
                    <td>東京都港区高輪四丁目23番6号ハイホーム高輪708</td>
                  </tr>

                  <tr>
                    <th className="text-right">事業内容</th>
                    <td>
                      STEAM教育・ICT化教育に関する教材等の
                      <br />
                      開発・コンサルティング
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <th className="text-right">代表</th>
                    <td>片山 昇</td>
                  </tr>
                </table>
              </div>
            </div>
            <h3 className="mt-5 mb-3">お問い合わせ</h3>
            <Contacts />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
