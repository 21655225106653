import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
//import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyA5mEp7ibxtHYRXrpIT238THcLTdmq6VEA",
  authDomain: "website-eteq-127c9.firebaseapp.com",
  projectId: "website-eteq-127c9",
  storageBucket: "website-eteq-127c9.appspot.com",
  messagingSenderId: "659667624078",
  appId: "1:659667624078:web:3c69aae14d8f4013c669df",
  measurementId: "G-30ZPV3306H",
});

const functions = getFunctions(app, "asia-northeast1");
//connectFunctionsEmulator(functions, "localhost", 5001);

export { functions };
