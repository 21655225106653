import React from "react";
import Footer from "./Footer";
import Contacts from "./Contacts";

function Yomumo() {
  return (
    <>
      <div className="contents container-fluid mt-1">
        <div className="row">
          <div className="col">
            <img src="/yomumo/yomumo.svg" alt="yomumo"></img>
            <h3 className="mt-5 mb-3">Yomumo (よむも) の概要</h3>
            <div className="row pl-sm-5 pl-1  mb-5">
              <div className="col-sm-7 col-12">
                学校の図書室で読んだ図書の感想を記録し共有するアプリです。
                タブレットのカメラで図書の裏表紙のバーコードをスキャンするだけで、
                本のタイトル・著者名・表紙などの情報を簡単に取り込むことができます。
                感想はキーボード入力・手書き入力両方に対応しています。
                タブレット(Android/iOS)・ノートパソコンなどに対応しています。
                試用期間を設けていますので、お気軽にお問い合わせください。
              </div>
              <div className="col-sm-5 col-12">
                <img
                  className="img-fluid"
                  src="/yomumo/yomumo-screenshot.png"
                  alt="yomumo"
                />
              </div>
            </div>
            <h3 className="mt-5">製品の特徴</h3>
            <div className="row pl-sm-5 pl-1 mb-5">
              <div className="col-12">
                <h4>図書の情報が簡単に入力できる</h4>
                PC・タブレットのカメラで図書のISBNバーコードを読み取るだけで、
                タイトル・著者名・出版社などの情報の他、図書の表紙を自動入力されます。
                遊び感覚で記録を残すことができ、感想の入力に集中できます。
              </div>
              <img
                className="col-6 img-fluid"
                src="/yomumo/yomumo-screenshot-1.png"
                alt="yomumo"
              />
              <img
                className="col-6 img-fluid"
                src="/yomumo/yomumo-screenshot-2.png"
                alt="yomumo"
              />
            </div>
            <div className="row pl-sm-5 pl-1  mb-5">
              <div className="col-sm-7 col-12">
                <h4>さまざまなな読書カードの閲覧方法</h4>
                自分で作成した読書カードを振り返ることができることはもちろん、
                同じ学級・学年・学校で作成された他の児童・生徒の読書カードを閲覧することができます。
                また、新着順や読書数が多い図書順、年月ごとに並び替えができます。
                <br />
                ※他校で作成された読書カードには学校名と学年までが表示され、学級名や個人名は表示されません。
              </div>
              <div className="col-sm-5 col-12">
                <img
                  className="img-fluid"
                  src="/yomumo/yomumo-screenshot-3.png"
                  alt="yomumo"
                />
              </div>
            </div>
            <div className="row pl-sm-5 pl-1  mb-5">
              <div className="col-sm-8 col-12">
                <h4>読書に対するモチベーションを刺激</h4>
                読書カードには図書の表紙が表示され、他の児童・生徒の目に触れることで、感想を入力する動機に繋がります。
                また、読書カードに「いいね」をすることができ、感想に対する共感を示すことができます。
                「いいね」はどの学校の児童・生徒からのものであるかがわかります(個人は特定できません)。
                異なる地域の学校からの評価が内発的なモチベーションを刺激します。
              </div>
              <div className="col-sm-4 col-12">
                <img
                  className="img-fluid"
                  src="/yomumo/yomumo-screenshot-4.png"
                  alt="yomumo"
                />
              </div>
            </div>
            <h3 className="mt-5 mb-3">管理機能</h3>
            <div className="row pl-sm-5 pl-1">
              <div className="col">
                <p>管理者・教員アカウントから以下のことができます。</p>

                <table className="table col-12">
                  <tbody>
                    <tr>
                      <th>ユーザー管理</th>
                      <td>
                        ユーザーの新規登録・編集・削除(CSVを使った一斉登録も可能)
                        <br />
                        ※ユーザー数は契約時の条件の範囲内で追加できます。
                        <br />
                        ※Google/Microsoftアカウントとの連携が可能です。
                      </td>
                    </tr>
                    <tr>
                      <th>カード管理</th>
                      <td>
                        学級・学年・学校ごとのカード枚数の確認
                        <br />
                        読書カードのエクスポート
                        <br />
                        不適切な読書カードの非表示
                      </td>
                    </tr>
                    <tr>
                      <th>その他</th>
                      <td>学校独自のサイトへのリンク管理</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h3 className="mt-5 mb-3">動作環境</h3>
            <div className="row pl-sm-5 pl-1">
              <table className="table col-12">
                <tbody>
                  <tr>
                    <th>対応端末</th>
                    <td>
                      Windows・Mac・Chromebook・Android・iPad
                      <br />
                      100万画素以上のカメラ(バーコード読み取りを利用する場合)
                      <br />
                      スタイラスペン(手書き入力を利用する場合)
                    </td>
                  </tr>
                  <tr>
                    <th>対応ブラウザ</th>
                    <td>
                      Chrome・Safari・Edge最新版
                      <br />
                      ※Internet Explorerでは動作しません。
                    </td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td>インターネット環境(WiFi等の常時接続)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 className="mt-5 mb-3">お問い合わせ</h3>
            <Contacts />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Yomumo;
