import React from "react";
import Footer from "./Footer";

function Yomumo() {
  return (
    <>
      <div className="contents container-fluid mt-1">
        <h3 className="mt-5 mb-3">特定商取引法に基づく表記</h3>
        <div className="row pl-sm-5 pl-1">
          <div className="col">
            <table className="table table-borderless text-black-70 pl-5">
              <tr>
                <th className="text-right">販売業社の名称</th>
                <td>株式会社ETeq</td>
              </tr>
              <tr>
                <th className="text-right">所在地</th>
                <td>東京都港区高輪四丁目23番6号ハイホーム高輪708</td>
              </tr>
              <tr>
                <th className="text-right">電話番号</th>
                <td>
                  050-5809-3036
                  <br />
                  受付時間 10:00-17:00（土日祝を除く）
                </td>
              </tr>
              <tr>
                <th className="text-right">メールアドレス</th>
                <td>info@eteq.co.jp</td>
              </tr>
              <tr>
                <th className="text-right">ウェブサイト</th>
                <td>https://eteq.co.jp/</td>
              </tr>
              <tr>
                <th className="text-right">運営統括責任者</th>
                <td>片山 昇</td>
              </tr>
              <tr>
                <th className="text-right">追加手数料等の追加料金 </th>
                <td>
                  個別のページに記載のない場合は、以下の通りです。
                  <br />
                  ・配送料（一律1,000円/箱） <br />
                  ・手数料（コンビニ決済：100円
                </td>
              </tr>
              <tr>
                <th className="text-right">交換および返品（返金ポリシー）</th>
                <td>
                  ＜お客様都合の返品・交換の場合＞
                  <br />
                  発送処理前の商品：ウェブサイトのキャンセルボタンを押すことで注文のキャンセルが可能です。
                  <br />
                  発送処理後の商品：未開封の商品は、商品到着後10日以内に弊社にご連絡いただいた場合に限り、お客様の送料負担にて返金又は同額以下の商品と交換いたします。開封後の商品は、返品・交換はお受けしておりません。
                  ＜商品に不備がある場合＞
                  <br />
                  当社の送料負担にて返金又は新しい商品と交換いたします。まずは弊社までご連絡ください。
                </td>
              </tr>
              <tr>
                <th className="text-right">引渡時期</th>
                <td>
                  注文は 3～5営業日以内に処理され、商品は 14
                  日以内に到着します。 注文後すぐにご利用いただけます。
                </td>
              </tr>
              <tr>
                <th className="text-right">受け付け可能な決済手段 </th>
                <td>クレジットカードまたは国内の銀行振込</td>
              </tr>
              <tr>
                <th className="text-right">決済期間</th>
                <td>
                  クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から
                  3 日以内にお振り込みいただく必要があります。
                </td>
              </tr>
              <tr>
                <th className="text-right">販売価格</th>
                <td>各商品ページに記載の金額</td>
              </tr>
              <tr>
                <th className="text-right">ウェブサイト</th>
                <td>https://eteq.co.jp/</td>
              </tr>

              <tr>
                <th className="text-right">商品代金以外の必要料金</th>
                <td>消費税</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Yomumo;
