import React, { useState } from "react";
import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

export default function Contacts(): JSX.Element {
  const [sent, setSent] = useState(false);

  function onSubmit(e: any) {
    console.log("a");
    e.preventDefault();

    const name = e.target[0].value;
    const affiliation = e.target[1].value;
    const email = e.target[2].value;
    const contents = e.target[3].value;

    grecaptcha.ready(async function () {
      const token = await grecaptcha.execute(
        "6LcLN4wcAAAAAEVwTV9HJGNFyDrx8_zGhJXbL9tB",
        {
          action: "submit",
        }
      );
      console.log(token);
      const func = httpsCallable(functions, "sendEmail");
      const result = await func({
        token: token,
        name: name,
        affiliation: affiliation,
        email: email,
        contents: contents,
      });
      console.log(result);
      setSent(true);
    });
  }

  return (
    <>
      <form onSubmit={onSubmit} className="row  mb-3">
        <label className="col-md-4 col-form-label">お名前</label>
        <div className="col-md-8 mb-3">
          <input className="form-control" type="text" name="name" />
        </div>
        <label className="col-md-4 col-form-label">ご所属</label>
        <div className="col-md-8 mb-3">
          <input className="form-control" type="text" name="affiliation" />
        </div>
        <label className="col-md-4 col-form-label">E-mail</label>
        <div className="col-md-8 mb-3">
          <input className="form-control" type="text" name="email" />
        </div>
        <label className="col-md-4 col-form-label">お問い合わせ内容</label>
        <div className="col-md-8 mb-3">
          <textarea className="form-control" name="content" rows={7}></textarea>
        </div>
        <div className="col d-flex justify-content-end">
          {sent ? (
            <>
              <p className="me-3">
                お問い合わせありがとうございました / Thank you for contuctiong
                us.
              </p>
              <button type="submit" className="btn btn-primary ms-3 " disabled>
                送信済み/Sent
              </button>
            </>
          ) : (
            <button type="submit" className="btn btn-primary ms-3 mb-5">
              送信/Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
}
