export default function App() {
  return (
    <footer className="footer mt-auto py-3 bg-dark">
      <div className="container text-center text-white">
        <div className="">
          <a href="/tokutei">特定商取引法に基づく表記</a>
        </div>
        <div className="">2020 all rights reserved by ETeq, Inc.</div>
      </div>
    </footer>
  );
}
